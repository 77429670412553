import {Component, Input, ViewEncapsulation} from '@angular/core';
import {NgIf, NgTemplateOutlet} from "@angular/common";
import {SafeHtml} from "@angular/platform-browser";

@Component({
  selector: 'app-content',
  standalone: true,
  imports: [
    NgIf,
    NgTemplateOutlet
  ],
  templateUrl: './content.component.html',
  styleUrl: './content.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class ContentComponent {
  @Input() style: 'default'|'notification'|'notificationDate'|'calendarEventCounter'|'calendarEventDate'|'calendarEventItem'|'small'|'userGroupMail' = 'default';
  @Input() innerHTML: SafeHtml = '';
  @Input() cssClass: string = '';
}
